// React imports
import { useCallback, useEffect, useMemo, useState } from "react";

// React Router imports
import { Link, useNavigate } from "react-router-dom";

// Providers
import invoicesProvider from "providers/invoicesProvider";

// Material UI Components
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import PaymentIcon from "@mui/icons-material/Payment";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Custom Components
import ActionsMenu from "components/Menu";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Table from "components/Table";
import VideoLoader from "components/Animation/VideoLoader";
import customAlert from "components/Alerts/CustomAlert";

// Utilities
import {
  calculateBillingPeriod,
  currencyFormatter,
  shortDateFormat,
} from "utils";

function RentInvoicesTable({ permanentQuery, query }) {
  // Context and hooks
  const navigate = useNavigate();

  // Derived states or memoized values
  const columns = useMemo(
    () => [
      { name: "id", align: "left" },
      { name: "cliente", align: "left" },
      { name: "unidad", align: "left" },
      { name: "periodo de cobro", align: "left" },
      { name: "fecha límite de pago", align: "left" },
      { name: "subtotal", align: "left" },
      { name: "recargo", align: "left" },
      { name: "total", align: "left" },
      { name: "pagado", align: "left" },
      { name: "pagado el", align: "left" },
      { name: "notas", align: "left" },
      { name: "estatus", align: "left" },
      { name: "opciones", align: "center" },
    ],
    []
  );

  // Loading and error states
  const [loading, setLoading] = useState(true);

  // General states
  const [state, setState] = useState({
    invoices: [],
    rows: [],
    total: 0,
    page: 0,
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { data: invoicesData, total },
      } = await invoicesProvider.getInvoices(state.page + 1, {
        ...query,
        ...permanentQuery,
        invoice_type_matches: "renta",
      });
      const invoiceDetails = invoicesData.map((invoice) => {
        const customerName = invoice.customer
          ? `${invoice.customer.first_name} ${invoice.customer.last_name}`
          : "Cliente desconocido";

        const unitName = invoice.unit?.name || "Sin nombre";
        return { ...invoice, unitName, customerName };
      });

      setState((prevState) => ({
        ...prevState,
        invoices: invoiceDetails || [],
        total: total || 0,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        invoices: [],
        total: 0,
      }));
    } finally {
      setLoading(false);
    }
  }, [state.page, query, permanentQuery]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, state.page]);

  const deleteInvoice = useCallback(
    async function (invoiceId) {
      const result = await customAlert({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará el cobro de manera permanente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          await invoicesProvider.deleteInvoice(invoiceId);
          await fetchData(state.page);
          customAlert({
            title: "Eliminado",
            text: "El cobro ha sido eliminado.",
            icon: "success",
          });
          navigate("/invoices");
        } catch (error) {
          customAlert({
            title: "Error",
            text: "Hubo un problema al eliminar el cobro.",
            icon: "error",
          });
        }
      }
    },
    [fetchData, state.page, navigate]
  );

  // Format invoice data into rows for the table
  useEffect(() => {
    if (state.invoices && state.invoices.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.invoices.map((invoice) => ({
          id: (
            <MKTypography variant="button" fontWeight="medium">
              {invoice.id}
            </MKTypography>
          ),
          cliente: (
            <MKButton
              variant="text"
              color="dark"
              sx={{
                padding: 0,
                minWidth: "auto",
                fontSize: "inherit",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "primary.main",
                },
              }}
              component={Link}
              to={`/customers/${invoice.customer.id}#Cobros`}
            >
              {`${invoice.customer.first_name} ${invoice.customer.last_name}`}
            </MKButton>
          ),
          unidad: (
            <MKTypography variant="button" fontWeight="medium">
              {`${invoice.unit.floor || ""}${invoice.unit.block || ""} ${
                invoice.unit.name
              }`}
            </MKTypography>
          ),
          "periodo de cobro": (
            <MKTypography variant="button" fontWeight="medium">
              {calculateBillingPeriod(invoice.start_date, invoice.end_date)}
            </MKTypography>
          ),
          "fecha límite de pago": (
            <MKTypography variant="button" fontWeight="medium">
              {shortDateFormat(invoice.payment_deadline)}
            </MKTypography>
          ),
          subtotal: (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(invoice.subtotal)}
            </MKTypography>
          ),
          recargo: (
            <MKTypography variant="button" fontWeight="medium">
              {invoice.surcharge ? `$${invoice.surcharge}` : "Sin recargo"}
            </MKTypography>
          ),
          total: (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(Number(invoice.total))}
            </MKTypography>
          ),
          pagado: (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(invoice.paid_amount)}
            </MKTypography>
          ),
          "pagado el": (
            <MKTypography variant="button" fontWeight="medium">
              {shortDateFormat(invoice.paid_date)}
            </MKTypography>
          ),
          notas: (
            <MKTypography variant="button" fontWeight="medium">
              {invoice.notes || ""}
            </MKTypography>
          ),
          estatus: (
            <MKTypography variant="button" fontWeight="medium">
              {invoice.status}
            </MKTypography>
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  {
                    text: "Editar",
                    icon: <EditIcon />,
                    onClick: () => navigate(`/invoices/${invoice.id}/edit`),
                  },
                  {
                    text: "Ver Pagos",
                    icon: <VisibilityIcon />,
                    onClick: () => {
                      const defaultTab =
                        invoice.invoice_type === "renta" ? 0 : 1;
                      navigate(`/payments?invoice_id=${invoice.id}`, {
                        state: { defaultTab },
                      });
                    },
                  },
                  {
                    text: "Pagar",
                    icon: <PaymentIcon />,
                    onClick: () => {
                      const defaultTab =
                        invoice.invoice_type === "renta" ? 0 : 1;
                      navigate(
                        `/payments/${invoice.id}/new?type=${defaultTab}`
                      );
                    },
                  },

                  {
                    text: "Eliminar",
                    icon: <DeleteIcon />,
                    onClick: () => deleteInvoice(invoice.id),
                  },
                ]}
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [state.invoices, deleteInvoice, navigate]);

  return (
    <Grid container item xs={12} lg={12}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="15vh"
        >
          <VideoLoader />
        </Box>
      ) : state.rows.length === 0 ? (
        <MKTypography variant="h6" fontWeight="medium">
          No se encontraron cobros de renta
        </MKTypography>
      ) : (
        <Table
          columns={columns}
          rows={state.rows}
          total={state.total}
          page={state.page}
          handlePageChange={(_ev, newPage) =>
            setState((prevState) => ({ ...prevState, page: newPage }))
          }
        />
      )}
    </Grid>
  );
}

export default RentInvoicesTable;
