// React imports
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// React Router imports
import { Link, useLocation, useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material UI Components
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import EvictIcon from "@mui/icons-material/Block";
import Grid from "@mui/material/Grid";
import RentIcon from "@mui/icons-material/House";
import Switch from "@mui/material/Switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@mui/material";

// Custom Components
import ActionsMenu from "components/Menu";
import Table from "components/Table";
import Toolbar from "components/Toolbar";
import VideoLoader from "components/Animation/VideoLoader";
import customAlert from "components/Alerts/CustomAlert";

// Providers
import rentalsProvider from "providers/rentalsProvider";
import unitsProvider from "providers/unitsProvider";

// Contexts
import SessionContext from "contexts/SessionContext";

// Utilities
import { currencyFormatter, shortDateFormat } from "utils";

function UnitsPage() {
  // Context and hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useContext(SessionContext);

  // Derived states or memoized values
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}_cont`] = value;
      return acc;
    }, {});
  }, [location.search]);

  const columns = useMemo(
    () => [
      { name: "nombre", align: "left" },
      { name: "piso", align: "left" },
      { name: "bloque", align: "left" },
      { name: "ancho", align: "left" },
      { name: "largo", align: "left" },
      { name: "altura", align: "left" },
      { name: "precio", align: "left" },
      { name: "ireegular", align: "left" },
      { name: "metros", align: "left" },
      { name: "estatus", align: "left" },
      { name: "bloquear", align: "left" },
      { name: "opciones", align: "center" },
    ],
    []
  );

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // General states
  const [state, setState] = useState({
    units: [],
    rows: [],
    total: 0,
    page: 0,
    query: searchParams,
    blockedUnits: {},
  });

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback((_ev, newPage) => {
    setState((prevState) => ({ ...prevState, page: newPage }));
  }, []);

  // Fetches unit data and updates the state with the list of units and total count
  const fetchData = useCallback(async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const {
        data: { data: unitsData, total },
      } = await unitsProvider.getUnits(state.page + 1, state.query);
      setState((prevState) => ({
        ...prevState,
        units: unitsData,
        total,
      }));
    } catch (error) {
      console.error("Error fetching units data", error);
      let errorMsg =
        "Hubo un problema al cargar los datos de las unidades. Por favor, intenta de nuevo.";
      if (error.response && error.response.data) {
        errorMsg = Array.isArray(error.response.data)
          ? error.response.data.join(", ")
          : error.response.data;
      }
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  }, [state.page, state.query]);

  // Triggers fetchData when page or query changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Toggles the suspension state of a unit in the state
  const handleBlockChange = useCallback(
    async (unitId) => {
      setLoading(true);
      const isBlocked = !state.blockedUnits[unitId];

      setState((prevState) => ({
        ...prevState,
        blockedUnits: {
          ...prevState.blockedUnits,
          [unitId]: isBlocked,
        },
      }));
      const newStatus = isBlocked ? "bloqueada" : "disponible";
      await unitsProvider.updateUnit(unitId, { status: newStatus });
      fetchData();
    },
    [state.blockedUnits, fetchData]
  );

  // Función para desalojar la unidad
  const handleEvict = useCallback(
    async (activeRental) => {
      // Si el contrato está terminado la unidad se puede desalojar sin motivo de cancelación
      if (activeRental.status === "terminado") {
        customAlert({
          title: "¿Estás seguro?",
          text: "La unidad será desalojada",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Desalojar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              setLoading(true);
              await unitsProvider.updateUnit(activeRental.unit_id, {
                status: "disponible",
              });

              await fetchData();

              customAlert({
                title: "Unidad desalojada!",
                text: "La unidad ha sido desalojada exitosamente.",
                icon: "success",
              });
            } catch (error) {
              customAlert({
                title: "Error",
                text: "Hubo un problema al desalojar unidad",
                icon: "error",
              });
              setLoading(false);
            }
          }
        });
      } else {
        // Si el contrato está activo o atrasado, se solicita un motivo de cancelación
        customAlert({
          title: "¿Estás seguro?",
          text: "Escribe el motivo de desalojo y cancelación de contrato",
          input: "text",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Desalojar",
          cancelButtonText: "Mantener contrato",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              setLoading(true);
              await rentalsProvider.updateRental(activeRental.id, {
                status: "cancelado",
                motive: result.value,
              });

              await fetchData();

              customAlert({
                title: "Unidad desalojada!",
                text: "La unidad ha sido desalojada exitosamente.",
                icon: "success",
              });
            } catch (error) {
              customAlert({
                title: "Error",
                text: "Hubo un problema al cancelar el contrato.",
                icon: "error",
              });
              setLoading(false);
            }
          }
        });
      }
    },
    [fetchData]
  );

  // Exportar unidades a excel, recibe el query de búsqueda y descarga el archivo
  const exportUnits = useCallback(async () => {
    try {
      setLoading(true);
      const { data: blobData } = await unitsProvider.exportUnits(state.query);
      const url = window.URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = `unidades-${shortDateFormat(new Date())}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      customAlert({
        title: "Error",
        text: "Hubo un problema al exportar las unidades.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [state.query]);

  // Deletes a unit and refreshes the unit list
  const handleDeleteUnit = useCallback(
    (unitId) => {
      customAlert({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer.Se perderá toda la información de la unidad.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await unitsProvider.deleteUnit(unitId);
            fetchData();
            customAlert({
              title: "Eliminado!",
              text: "La unidad ha sido eliminada.",
              icon: "success",
              confirmButtonColor: "#3085d6",
            });
            navigate("/units");
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Ocurrió un error al intentar eliminar la unidad.",
              icon: "error",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    [fetchData, navigate]
  );

  // Función para quitar la unidad del mapa
  const handleUnmapUnit = useCallback(
    async (unitId) => {
      customAlert({
        title: "¿Quitar unidad del mapa?",
        text: "Esta acción no elimina la unidad, pero la quitará del mapa actual.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sí, quitar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            setLoading(true);
            await unitsProvider.updateUnit(unitId, { is_mapped: false }); // Actualizar en la base de datos
            customAlert({
              title: "Unidad quitada del mapa",
              text: "La unidad ya no está visible en el mapa.",
              icon: "success",
              confirmButtonColor: "#3085d6",
            });
            await fetchData(); // Recargar la lista de unidades
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Ocurrió un problema al quitar la unidad del mapa.",
              icon: "error",
              confirmButtonColor: "#d33",
            });
          } finally {
            setLoading(false);
          }
        }
      });
    },
    [fetchData]
  );

  // Maps the unit data into table rows, updating the "rows" state whenever the units or suspendedUnits state changes.
  // This ensures that the table is always updated with the correct information, including the suspension status.
  useEffect(() => {
    if (state.units && state.units.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.units.map((unit) => ({
          nombre: (
            <MKTypography variant="button" fontWeight="medium">
              {unit.name || ""}
            </MKTypography>
          ),
          piso: (
            <MKTypography variant="button" fontWeight="medium">
              {unit.floor || ""}
            </MKTypography>
          ),
          bloque: (
            <MKTypography variant="button" fontWeight="medium">
              {unit.block || ""}
            </MKTypography>
          ),
          ancho: (
            <MKTypography variant="button" fontWeight="medium">
              {unit.width || ""}
            </MKTypography>
          ),
          largo: (
            <MKTypography variant="button" fontWeight="medium">
              {unit.length || ""}
            </MKTypography>
          ),
          altura: (
            <MKTypography variant="button" fontWeight="medium">
              {unit.height || ""}
            </MKTypography>
          ),
          precio: (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(unit.default_price)}
            </MKTypography>
          ),
          ireegular: (
            <MKTypography variant="button" fontWeight="medium">
              {unit.is_irregular ? "Sí" : "No"}
            </MKTypography>
          ),
          metros: (
            <MKTypography variant="button" fontWeight="medium">
              {unit.square_meters || ""}
            </MKTypography>
          ),
          estatus: (
            <MKTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {unit.status}
            </MKTypography>
          ),
          bloquear: (
            <Switch
              checked={
                unit.status === "bloqueada" ||
                state.blockedUnits[unit.id] ||
                false
              }
              onChange={() => handleBlockChange(unit.id)}
            />
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  {
                    icon: <EditIcon />,
                    text: "Editar",
                    onClick: () => navigate(`/units/${unit.id}/edit`),
                  },
                  {
                    icon: <DeleteIcon />,
                    text: "Eliminar",
                    onClick: () => handleDeleteUnit(unit.id),
                  },
                  {
                    icon: <VisibilityIcon />,
                    text: "Ver historial de contratos",
                    onClick: () => navigate(`/units/${unit.id}/rentals`),
                  },
                  {
                    icon: <RentIcon />,
                    text: "Rentar",
                    disabled: unit.status !== "disponible",
                    onClick: () =>
                      navigate("/rentals/new", { state: { unitId: unit.id } }),
                  },
                  {
                    icon: <EvictIcon />,
                    text: "Desalojar",
                    disabled:
                      unit.status !== "rentada" && unit.status !== "atrasada",
                    onClick: () => handleEvict(unit.active_rental),
                  },
                  {
                    icon: <LocationOffIcon />,
                    text: "Quitar del mapa",
                    disabled: !unit.is_mapped,
                    onClick: () => handleUnmapUnit(unit.id),
                  },
                ]}
                disabled={
                  unit.status === "bloqueada" ||
                  state.blockedUnits[unit.id] ||
                  false
                }
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [
    state.units,
    navigate,
    fetchData,
    handleDeleteUnit,
    handleBlockChange,
    state.blockedUnits,
    handleEvict,
    handleUnmapUnit,
  ]);

  return (
    <MainLayout title="Unidades">
      <Toolbar
        setQuery={(query) =>
          setState((prevState) => ({
            ...prevState,
            query,
          }))
        }
        ransackQuery="name_or_floor_or_block_or_status_cont"
        newItemButton={
          <Box display="flex" gap={2}>
            <MKButton
              variant="contained"
              size="small"
              color="primary"
              component={Link}
              to="/units/new"
            >
              + Agregar Unidad
            </MKButton>
            <MKButton
              variant="contained"
              size="small"
              color="primary"
              component={Link}
              to="/units/map"
            >
              Mapa
            </MKButton>
            {[1, 2].includes(currentUser.role_id) && (
              <Tooltip title="Exportar a Excel">
                <MKButton iconOnly onClick={exportUnits}>
                  <DownloadIcon />
                </MKButton>
              </Tooltip>
            )}
          </Box>
        }
      />

      {errorMessage && (
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "15vh" }}
        >
          <MKAlert color="error" dismissible={true}>
            {errorMessage}
          </MKAlert>
        </Grid>
      )}

      <Grid container item xs={12} lg={12}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="15vh"
          >
            <VideoLoader />
          </Box>
        ) : state.rows.length === 0 ? (
          <MKTypography variant="h6" fontWeight="medium">
            No se encontraron unidades
          </MKTypography>
        ) : (
          <Table
            columns={columns}
            rows={state.rows}
            total={state.total}
            page={state.page}
            handlePageChange={handlePageChange}
          />
        )}
      </Grid>
    </MainLayout>
  );
}

export default UnitsPage;
