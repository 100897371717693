// React imports
import { useEffect, useCallback, useState } from "react";

// React Router imports
import { useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material UI Components
import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Custom Components
import VideoLoader from "components/Animation/VideoLoader";
import ProductForm from "pages/Payments/tabs/productForm.js";

// Providers
import customersProvider from "providers/customersProvider";
import invoicesProvider from "providers/invoicesProvider";
import paymentsProvider from "providers/paymentsProvider";
import productsProvider from "providers/productsProvider";

function NewPaymentProduct() {
  // Context and hooks
  const navigate = useNavigate();

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState("");

  // Data-specific states
  const [selectedPaymentForm, setSelectedPaymentForm] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [paymentForms, setPaymentForms] = useState([]);
  const [productForms, setProductForms] = useState([
    { id: Date.now(), payment: {} },
  ]);
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [payment, setPayment] = useState({
    date: new Date().toISOString().split("T")[0],
    payment_type: "producto",
    payable_type: "Product",
    status: "pendiente",
    customer_id: "",
    method: "",
    reference_number: "",
    concept: "",
    notes: "",
    amount: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: paymentFormsData } =
          await invoicesProvider.getPaymentForms();
        setPaymentForms(paymentFormsData || []);

        const { data: productsData } = await productsProvider.getProducts();
        setProducts(productsData?.data || []);

        const {
          data: { data: customersData },
        } = await customersProvider.getCustomers(null, {
          status_in: ["activo", "inactivo", "atrasado"],
        });
        setCustomers(customersData || []);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setFormErrors("Error al cargar los datos.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    setPayment((prevPayment) => ({
      ...prevPayment,
      [name]: value,
    }));
  };

  const handlePaymentFormChange = (_ev, value) => {
    if (value) {
      setSelectedPaymentForm(value);
      setPayment((prevPayment) => ({
        ...prevPayment,
        payment_form: value.Value,
      }));
    } else {
      setSelectedPaymentForm(null);
      setPayment((prevPayment) => ({
        ...prevPayment,
        payment_form: "",
      }));
    }
  };

  const handleCustomerChange = (_ev, value) => {
    if (value) {
      setSelectedCustomer(value);
      setPayment((prevPayment) => ({
        ...prevPayment,
        customer_id: value.id,
      }));
    } else {
      setSelectedCustomer(null);
      setPayment((prevPayment) => ({
        ...prevPayment,
        customer_id: "",
      }));
    }
  };

  const handleAddProductForm = () => {
    setProductForms((prevForms) => [
      ...prevForms,
      { id: Date.now(), payment: {} },
    ]);
  };

  const handlePaymentChange = useCallback((id, payment) => {
    setProductForms((prevForms) =>
      prevForms.map((form) => (form.id === id ? { ...form, payment } : form))
    );
  }, []);

  const handleRemoveProductForm = (id) => {
    setProductForms((prevForms) => {
      if (prevForms.length === 1) {
        return prevForms;
      }
      return prevForms.filter((form) => form.id !== id);
    });
  };

  const submitForm = async (event) => {
    event.preventDefault();
    const payload = {
      ...payment,
      products: productForms.map((form) => form.payment),
      amount: productForms.reduce(
        (acc, form) => acc + Number(form.payment.amount),
        0
      ),
    };
    try {
      const { data: newPayment } = await paymentsProvider.createPayment(
        payload
      );
      navigate("/payments", {
        state: {
          feedback: "Pago creado correctamente",
          newPayment: JSON.stringify(newPayment),
        },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  useEffect(() => {});
  return (
    <MainLayout title="Nuevo Pago de Producto">
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {formErrors && (
                  <MKAlert color="error" dismissible={true}>
                    {formErrors}
                  </MKAlert>
                )}
                <MKBox
                  p={3}
                  my={2}
                  border="1px solid #e0e0e0"
                  borderRadius="8px"
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedCustomer}
                        options={
                          customers.map((customer) => ({
                            label: `${customer.first_name} ${customer.last_name}`,
                            id: customer.id,
                          })) || []
                        }
                        getOptionLabel={(option) =>
                          option ? option.label : "Selecciona un cliente"
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Cliente"
                            fullWidth
                          />
                        )}
                        onChange={handleCustomerChange}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedPaymentForm}
                        options={paymentForms}
                        getOptionLabel={(option) =>
                          option
                            ? `[${option.Value}] ${option.Name}`
                            : "Forma de pago"
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Forma de pago"
                            fullWidth
                          />
                        )}
                        onChange={handlePaymentFormChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Concepto"
                        name="concept"
                        value={payment.concept}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </MKBox>

                {productForms.map((form) => (
                  <ProductForm
                    key={form.id}
                    products={products}
                    onPaymentChange={(payment) =>
                      handlePaymentChange(form.id, payment)
                    }
                    onRemove={() => handleRemoveProductForm(form.id)}
                    showRemoveButton={productForms.length > 1}
                  />
                ))}

                <Grid container item justifyContent="center" xs={12} my={2}>
                  <MKButton
                    variant="outlined"
                    color="primary"
                    onClick={handleAddProductForm}
                    fullWidth
                  >
                    Agregar producto
                  </MKButton>
                </Grid>
                <Grid container item justifyContent="center" xs={12} my={2}>
                  <MKButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Crear pago de producto
                  </MKButton>
                </Grid>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}
export default NewPaymentProduct;
