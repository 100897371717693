// React imports
import { useContext, useEffect, useState } from "react";

// External Libraries
import { useNavigate, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material UI Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { InputAdornment } from "@mui/material";

// Custom Components
import VideoLoader from "components/Animation/VideoLoader";

// Providers
import invoicesProvider from "providers/invoicesProvider";

// Utilities
import { currencyFormatter, shortDateFormat } from "utils";

// Contexts
import SessionContext from "contexts/SessionContext";

function EditInvoicePage() {
  // Context and hooks
  const { currentUser } = useContext(SessionContext);
  const { id } = useParams(); // Get parameters from the URL
  const navigate = useNavigate(); // React Router hook for navigation

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Data-specific states
  const [invoice, setInvoice] = useState({
    start_date: "",
    end_date: "",
    payment_deadline: "",
    subtotal: "",
    total: "",
    surcharge: "",
    notes: "",
  });
  const [invoiceMonthlyRent, setInvoiceMonthlyRent] = useState(null);
  const [lastInvoiceDate, setLastInvoiceDate] = useState(null);

  // Focus states
  const [focusedSubtotal, setFocusedSubtotal] = useState(false);
  const [focusedSurcharge, setFocusedSurcharge] = useState(false);

  // Handlers
  const handleFocusSubtotal = () => setFocusedSubtotal(true);
  const handleBlurSubtotal = () => setFocusedSubtotal(false);
  const handleFocusSurcharge = () => setFocusedSurcharge(true);
  const handleBlurSurcharge = () => setFocusedSurcharge(false);

  useEffect(() => {
    const fetchInvoiceData = async () => {
      setLoading(true);
      setErrorMessage(null);
      try {
        const { data } = await invoicesProvider.getInvoice(id);
        setInvoice({
          start_date: data.start_date,
          end_date: data.end_date,
          payment_deadline: data.payment_deadline,
          subtotal: data.subtotal,
          total: data.total,
          surcharge: data.surcharge,
          notes: data.notes,
        });
        setInvoiceMonthlyRent(data.rental.monthly_rent);
        setLastInvoiceDate(data.rental.last_invoice_date);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
        let errorMsg = "Error al cargar los datos del cobro";
        if (error.response && error.response.data) {
          errorMsg = Array.isArray(error.response.data)
            ? error.response.data.join(", ")
            : error.response.data;
        }
        setErrorMessage(errorMsg);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceData();
  }, [id]);

  // Handles changes in the form input fields and updates invoice state
  const handleInputChange = ({ target: { name, value } }) => {
    let formattedValue = value;

    if (name === "end_date" && value) {
      const selectedEndDate = new Date(value);
      if (!isNaN(selectedEndDate)) {
        formattedValue = selectedEndDate.toISOString().split("T")[0];
      } else {
        formattedValue = "";
      }
    }

    if (["subtotal", "surcharge"].includes(name)) {
      formattedValue =
        value === ""
          ? ""
          : Math.max(0, parseFloat(value)) === parseFloat(value)
          ? value // Mantener el valor si es un número positivo, con decimales que empiecen en 0 (100.05)
          : "0";
    }

    const newSubtotal =
      name === "subtotal"
        ? parseFloat(formattedValue)
        : parseFloat(invoice.subtotal);
    const newSurcharge =
      name === "surcharge"
        ? parseFloat(formattedValue)
        : parseFloat(invoice.surcharge);

    const newTotal = newSubtotal + newSurcharge;

    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      [name]: formattedValue,
      total: newTotal,
    }));
  };

  const handleStartDateChange = ({ target: { value } }) => {
    if (!value) {
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        start_date: "",
        end_date: "",
        payment_deadline: "",
      }));
      return;
    }

    const newStartDate = new Date(value);

    const tentativeEndDate = new Date(
      newStartDate.getUTCFullYear(),
      newStartDate.getUTCMonth() + 1,
      newStartDate.getUTCDate()
    );

    if (tentativeEndDate.getUTCDate() !== newStartDate.getUTCDate()) {
      tentativeEndDate.setUTCDate(0);
    } else {
      tentativeEndDate.setUTCDate(tentativeEndDate.getUTCDate() - 1);
    }

    const newPaymentDeadline = new Date(tentativeEndDate);
    newPaymentDeadline.setUTCDate(
      newPaymentDeadline.getUTCDate() +
        Number(currentUser.subsidiary.payment_deadline_days || 5)
    );

    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      start_date: newStartDate.toISOString().split("T")[0],
      end_date: tentativeEndDate.toISOString().split("T")[0],
      payment_deadline: newPaymentDeadline.toISOString().split("T")[0],
    }));
  };

  useEffect(() => {
    if (!invoice.end_date) return;

    const newPaymentDeadline = new Date(invoice.end_date);
    newPaymentDeadline.setUTCDate(
      newPaymentDeadline.getUTCDate() +
        Number(currentUser.subsidiary.payment_deadline_days || 5)
    );
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      payment_deadline: newPaymentDeadline.toISOString().split("T")[0],
    }));

    if (!invoiceMonthlyRent || !invoice.start_date || !invoice.end_date) return;

    const invoiceStartDate = new Date(invoice.start_date);
    const invoiceEndDate = new Date(invoice.end_date);

    const yearDiff =
      invoiceEndDate.getUTCFullYear() - invoiceStartDate.getUTCFullYear();
    const monthDiff =
      invoiceEndDate.getUTCMonth() - invoiceStartDate.getUTCMonth();

    let months = yearDiff * 12 + monthDiff;
    if (invoiceEndDate.getUTCDate() >= invoiceStartDate.getUTCDate()) {
      months += 1;
    }
    const subtotal = Number(invoiceMonthlyRent) * months;

    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      subtotal,
      total: subtotal + Number(invoice.surcharge),
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.start_date, invoice.end_date, invoiceMonthlyRent]);

  // Submits the form after validating the data and handling errors
  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage(null);
    try {
      await invoicesProvider.updateInvoice(id, invoice);
      navigate("/invoices", {
        state: { feedback: "Cobro actualizado correctamente" },
      });
    } catch (error) {
      console.error(error);
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = Array.isArray(error.response.data)
          ? error.response.data.join(", ")
          : error.response.data;
      }
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout title="Editar Cobro">
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {errorMessage && (
                  <MKAlert color="error" dismissible={true}>
                    {errorMessage}
                  </MKAlert>
                )}
                {invoiceMonthlyRent && (
                  <MKBox mb={3}>
                    <MKAlert color="info">
                      <MKBox color="white" fontSize="1rem">
                        Precio mensual del contrato:
                        {currencyFormatter.format(invoiceMonthlyRent)} &nbsp;
                      </MKBox>
                      {lastInvoiceDate && (
                        <MKBox color="white" fontSize="1rem">
                          | Fecha del último cobro:{" "}
                          {shortDateFormat(lastInvoiceDate)}
                        </MKBox>
                      )}
                    </MKAlert>
                  </MKBox>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="start_date"
                        label="Fecha de inicio de periodo"
                        InputLabelProps={{ shrink: true }}
                        value={invoice.start_date}
                        onChange={handleStartDateChange}
                        required
                        type="date"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="end_date"
                        label="Fecha de fin de periodo"
                        InputLabelProps={{ shrink: true }}
                        value={invoice.end_date}
                        onChange={handleInputChange}
                        required
                        type="date"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="payment_deadline"
                        label="Fecha límite de pago"
                        InputLabelProps={{ shrink: true }}
                        value={invoice.payment_deadline}
                        onChange={handleInputChange}
                        required
                        type="date"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="subtotal"
                        label="Subtotal"
                        value={invoice.subtotal}
                        onChange={handleInputChange}
                        onFocus={handleFocusSubtotal}
                        onBlur={handleBlurSubtotal}
                        type="number"
                        variant="standard"
                        fullWidth
                        InputProps={{
                          startAdornment:
                            focusedSubtotal || invoice.subtotal ? (
                              <InputAdornment
                                position="start"
                                disableTypography
                                sx={{ fontSize: "0.8rem" }}
                              >
                                $
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="surcharge"
                        label="Recargo"
                        value={invoice.surcharge}
                        onChange={handleInputChange}
                        onFocus={handleFocusSurcharge}
                        onBlur={handleBlurSurcharge}
                        type="number"
                        variant="standard"
                        fullWidth
                        InputProps={{
                          startAdornment:
                            focusedSurcharge || invoice.surcharge ? (
                              <InputAdornment
                                position="start"
                                disableTypography
                                sx={{ fontSize: "0.8rem" }}
                              >
                                $
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="total"
                        label="Total"
                        value={currencyFormatter.format(invoice.total)}
                        type="text"
                        variant="standard"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        name="notes"
                        label="Notas"
                        value={invoice.notes}
                        onChange={handleInputChange}
                        type="text"
                        variant="standard"
                        fullWidth
                        required={false}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Actualizar
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}

export default EditInvoicePage;
