import React, { useCallback } from "react";
import colors from "assets/theme/base/colors";

//importar componentes de react-flow
import { NodeToolbar, Position } from "reactflow";

//importar componentes de Material-UI
import LocationOffIcon from "@mui/icons-material/LocationOff";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RentIcon from "@mui/icons-material/House";
import EvictIcon from "@mui/icons-material/Block";
import LockIcon from "@mui/icons-material/Lock";
import UnlockIcon from "@mui/icons-material/LockOpen";
import Tooltip from "@mui/material/Tooltip";

// Importar la función de alerta personalizada
import MKBox from "components/MKBox";
import customAlert from "components/Alerts/CustomAlert";

// Importar proveedores y hooks de navegación
import unitsProvider from "providers/unitsProvider";
import rentalsProvider from "providers/rentalsProvider";
import { useNavigate } from "react-router-dom";

const UnitNode = ({ data, isSelected, onDeleteNode }) => {
  const {
    label,
    status,
    width,
    height,
    activeRental,
    unit_id: id,
    is_irregular,
    svgPoints = "",
  } = data;

  const statusColor = colors.unitStatus[status] || "#000"; // Color por defecto si no hay estado
  const navigate = useNavigate();

  // Función para editar la unidad
  const handleEdit = useCallback(() => {
    navigate(`/units/${id}/edit`);
  }, [id, navigate]);

  // Función para rentar la unidad
  const handleRent = useCallback(() => {
    navigate(`/rentals/new`, { state: { unitId: id } });
  }, [id, navigate]);

  // Función para eliminar la unidad
  const handleDelete = useCallback(() => {
    customAlert({
      title: "¿Estás seguro?",
      text: "Esta acción no se puede deshacer. Se perderá toda la información de la unidad.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await unitsProvider.deleteUnit(id); // Eliminar de la base de datos
          customAlert({
            title: "Eliminado!",
            text: "La unidad ha sido eliminada.",
            icon: "success",
            confirmButtonColor: "#3085d6",
          }).then(() => {
            navigate(0); // Recarga la página cuando se eliminó una unidad
          });
        } catch (error) {
          customAlert({
            title: "Error",
            text: "Ocurrió un error al intentar eliminar la unidad.",
            icon: "error",
            confirmButtonColor: "#d33",
          });
        }
      }
    });
  }, [id, navigate]);

  // Función para quitar la unidad del mapa
  const handleUnmapUnit = useCallback(async () => {
    customAlert({
      title: "¿Quitar unidad del mapa?",
      text: "Esta acción no elimina la unidad, pero la quitará del mapa actual.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, quitar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await unitsProvider.updateUnit(id, { is_mapped: false }); // Actualizar en la base de datos
          customAlert({
            title: "Unidad quitada del mapa",
            text: "La unidad ya no está visible en el mapa.",
            icon: "success",
            confirmButtonColor: "#3085d6",
          }).then(() => {
            navigate(0); // Recarga la página cuando se quita la unidad
          });
        } catch (error) {
          customAlert({
            title: "Error",
            text: "Ocurrió un problema al quitar la unidad del mapa.",
            icon: "error",
            confirmButtonColor: "#d33",
          });
        }
      }
    });
  }, [id, navigate]);

  // Función para bloquear/desbloquear la unidad
  const handleBlockChange = useCallback(async () => {
    const newStatus = status === "bloqueada" ? "disponible" : "bloqueada";
    await unitsProvider.updateUnit(id, { status: newStatus });
  }, [id, status]);

  // Función para desalojar la unidad
  const handleEvict = useCallback(async () => {
    // Si el contrato está terminado la unidad se puede desalojar sin motivo de cancelación
    if (activeRental.status === "terminado") {
      customAlert({
        title: "¿Estás seguro?",
        text: "La unidad será desalojada",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Desalojar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await unitsProvider.updateUnit(activeRental.unit_id, {
              status: "disponible",
            });

            customAlert({
              title: "Unidad desalojada!",
              text: "La unidad ha sido desalojada exitosamente.",
              icon: "success",
            });
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al desalojar unidad",
              icon: "error",
            });
          }
        }
      });
    } else {
      // Si el contrato está activo o atrasado, se solicita un motivo de cancelación
      customAlert({
        title: "¿Estás seguro?",
        text: "Escribe el motivo de desalojo y cancelación de contrato",
        input: "text",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Desalojar",
        cancelButtonText: "Mantener contrato",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await rentalsProvider.updateRental(activeRental.id, {
              status: "cancelado",
              motive: result.value,
            });

            customAlert({
              title: "Unidad desalojada!",
              text: "La unidad ha sido desalojada exitosamente.",
              icon: "success",
            });
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al cancelar el contrato.",
              icon: "error",
            });
          }
        }
      });
    }
  }, [activeRental]);

  const svgStyles = {
    fill: statusColor, // Color dinámico según el estado
    transition: "fill 0.3s ease", // Transición suave para cambios de estado
    margin: 0,
  };

  // Calcular el ancho y alto máximo de los puntos del SVG
  let maxX = 0;
  let maxY = 0;
  let scaledPoints = svgPoints;

  if (is_irregular && svgPoints) {
    const points = svgPoints.split(" ").map((point) => point.split(","));
    points.forEach(([x, y]) => {
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    });

    // Escalar los puntos del SVG según el ancho y alto del contenedor
    const scaleX = width / maxX;
    const scaleY = height / maxY;
    scaledPoints = svgPoints
      .split(" ")
      .map((point) => {
        const [x, y] = point.split(",").map(Number);
        return `${x * scaleX},${y * scaleY}`;
      })
      .join(" ");
  }

  return (
    <div
      style={{
        padding: is_irregular ? 0 : 10,
        width: width || 200,
        height: height || 200,
        borderRadius: 5,
        backgroundColor: is_irregular ? "transparent" : statusColor,
        cursor: "grab",
        opacity: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: `${Math.min(width, height) / 3}px`,
        clipPath:
          is_irregular && svgPoints
            ? `polygon(${scaledPoints})` // Forma basada en los puntos del SVG
            : "none", // Forma estándar (rectángulo)
      }}
    >
      {is_irregular && (
        <svg
          viewBox={`0 0 ${width} ${height}`} // Vista del SVG para el contenedor
          xmlns="http://www.w3.org/2000/svg"
          style={{
            ...svgStyles,
            width: "100%", // Ajustar el ancho del SVG al contenedor
            height: "100%", // Ajustar la altura del SVG al contenedor
            objectFit: "contain", // Contener la figura dentro del contenedor
          }}
          preserveAspectRatio="xMidYMid meet" // Escalado proporcional al contenedor
        >
          <polygon points={scaledPoints} />
        </svg>
      )}

      <MKBox
        position="absolute"
        color="white"
        sx={{
          backgroundColor: "rgba(173, 172, 172, 0.39)", // Fondo gris opaco
          padding: "4px 8px", // Espaciado interno para que el texto no esté pegado al borde
          borderRadius: "4px", // Bordes redondeados opcionales
          zIndex: 10, // Asegura que el texto esté por encima de otros elementos
        }}
      >
        <strong>{label}</strong>
      </MKBox>

      <NodeToolbar
        isVisible={isSelected}
        position={Position.Top}
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Fondo blanco con opacidad
          borderRadius: 4, // Bordes redondeados para que se vea mejor
        }}
      >
        {/* Botón para Editar */}
        <Tooltip title="Editar" arrow>
          <IconButton size="small" color="primary" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>

        {/* Botón para Eliminar */}
        <Tooltip title="Eliminar" arrow>
          <IconButton size="small" color="error" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>

        {/* Botón para quitar la unidad del mapa */}
        <Tooltip title="Quitar del mapa" arrow>
          <IconButton size="small" color="default" onClick={handleUnmapUnit}>
            <LocationOffIcon />
          </IconButton>
        </Tooltip>

        {/* Mostrar Botón para Bloquear o Desbloquear */}
        {status === "bloqueada" ? (
          <Tooltip title="Desbloquear" arrow>
            <IconButton
              size="small"
              color="default"
              onClick={handleBlockChange}
            >
              <UnlockIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Bloquear" arrow>
            <IconButton
              size="small"
              color="default"
              onClick={handleBlockChange}
            >
              <LockIcon />
            </IconButton>
          </Tooltip>
        )}

        {/* Botón para Rentar (solo si está disponible) */}
        {status === "disponible" && (
          <Tooltip title="Rentar" arrow>
            <IconButton size="small" color="success" onClick={handleRent}>
              <RentIcon />
            </IconButton>
          </Tooltip>
        )}

        {/* Botón para Desalojar (solo si está rentada o atrasada) */}
        {(status === "rentada" || status === "atrasada") && (
          <Tooltip title="Desalojar" arrow>
            <IconButton size="small" color="warning" onClick={handleEvict}>
              <EvictIcon />
            </IconButton>
          </Tooltip>
        )}
      </NodeToolbar>
    </div>
  );
};

export default React.memo(UnitNode);
