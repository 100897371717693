// React imports
import { useEffect, useState } from "react";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material UI Components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

function ProductForm({
  products,
  onPaymentChange,
  onRemove,
  showRemoveButton,
}) {
  // Local states
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [payment, setPayment] = useState({
    price: "",
    amount: "",
    quantity: "",
    product_id: "",
  });

  // Focus states
  const [focusedAmount, setFocusedAmount] = useState(false);

  // Handlers
  const handleFocusAmount = () => setFocusedAmount(true);
  const handleBlurAmount = () => setFocusedAmount(false);

  useEffect(() => {
    onPaymentChange(payment);
  }, [payment, onPaymentChange]);

  const handleInputChange = ({ target: { name, value } }) => {
    let newValue = value;

    if (name === "quantity" && selectedProduct) {
      if (value === "") {
        newValue = "";
      } else {
        newValue = Math.min(
          Math.max(1, Number(value)),
          selectedProduct.stock || Infinity
        ).toString();
      }
    } else if (name === "amount") {
      newValue = value === "" ? "" : Math.max(0, Number(value) || 0);
    }

    setPayment((prevPayment) => ({
      ...prevPayment,
      [name]: newValue,
      amount:
        name === "quantity" && selectedProduct
          ? (selectedProduct.price || 0) * (Number(newValue) || 0)
          : name === "amount"
          ? newValue
          : prevPayment.amount,
    }));
  };

  const handleProductChange = (_event, value) => {
    setSelectedProduct(value);
    setPayment((prevPayment) => ({
      ...prevPayment,
      product_id: value ? value.id : undefined,
      payable_type: value ? "Product" : "",
      price: value ? value.price : "",
      quantity: value ? 1 : "",
      amount: value ? value.price : "",
    }));
  };

  return (
    <MKBox p={3} my={2} border="1px solid #e0e0e0" borderRadius="8px">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            value={selectedProduct}
            options={
              products.map((product) => ({
                label: `${product.name}`,
                id: product.id,
                stock: product.stock,
                price: product.price,
              })) || []
            }
            getOptionLabel={(option) =>
              option ? option.label : "Selecciona un producto"
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <MKInput
                {...params}
                variant="standard"
                label="Producto"
                fullWidth
              />
            )}
            onChange={handleProductChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MKInput
            variant="standard"
            label="Cantidad"
            name="quantity"
            type="number"
            value={payment.quantity}
            onChange={handleInputChange}
            fullWidth
            inputProps={{
              min: 1,
              max: selectedProduct ? selectedProduct.stock : 1,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MKInput
            variant="standard"
            label="Precio total por producto"
            name="amount"
            type="number"
            value={payment.amount}
            onChange={handleInputChange}
            onFocus={handleFocusAmount}
            onBlur={handleBlurAmount}
            fullWidth
            InputProps={{
              startAdornment:
                focusedAmount || payment.amount ? (
                  <InputAdornment
                    position="start"
                    disableTypography={true}
                    sx={{ fontSize: "0.8rem" }}
                  >
                    $
                  </InputAdornment>
                ) : null,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {showRemoveButton && (
            <MKButton
              color="error"
              variant="outlined"
              fullWidth
              onClick={onRemove}
            >
              Eliminar
            </MKButton>
          )}
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default ProductForm;
