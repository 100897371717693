// React imports
import { useEffect, useState } from "react";

// React Router imports
import { useNavigate, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material UI Components
import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

// Custom Components
import VideoLoader from "components/Animation/VideoLoader";

// Providers
import invoicesProvider from "providers/invoicesProvider";
import paymentsProvider from "providers/paymentsProvider";

function NewPaymentDeposit() {
  // Context and hooks
  const { id } = useParams();
  const navigate = useNavigate();

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState("");

  // Data-specific states
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedPaymentForm, setSelectedPaymentForm] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [paymentForms, setPaymentForms] = useState([]);
  const [payment, setPayment] = useState({
    payable_id: "",
    payable_type: "",
    date: new Date().toISOString().split("T")[0],
    quantity: "",
    amount: "",
    price: "",
    method: "",
    reference_number: "",
    concept: "",
    notes: "",
    payment_type: "depósito",
    status: "pendiente",
    customer_id: "",
  });

  // Focus states
  const [focusedAmount, setFocusedAmount] = useState(false);

  // Handlers
  const handleFocusAmount = () => setFocusedAmount(true);
  const handleBlurAmount = () => setFocusedAmount(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: paymentFormsData } =
          await invoicesProvider.getPaymentForms();
        setPaymentForms(paymentFormsData || []);

        const {
          data: { data: invoicesData = [] },
        } = await invoicesProvider.getInvoices(null, {
          status_not_in: ["pagado", "cancelado"],
          invoice_type_cont: payment.payment_type,
        });

        setInvoices(invoicesData || []);

        const matchingInvoice = invoicesData.find(
          (invoice) => invoice.id === parseInt(id)
        );

        if (matchingInvoice) {
          setSelectedInvoice({
            label: `#${matchingInvoice.id} - Unidad ${
              matchingInvoice.unit.floor ?? ""
            }${matchingInvoice.unit.block ?? ""} ${matchingInvoice.unit.name}`,
            id: matchingInvoice.id,
          });

          setPayment((prevPayment) => ({
            ...prevPayment,
            payable_id: matchingInvoice.id,
            payable_type: "Invoice",
            amount:
              Number(matchingInvoice.total) -
              Number(matchingInvoice.paid_amount),
          }));
        }
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setFormErrors("Error al cargar los datos.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, payment.payment_type]);

  const handleInputChange = ({ target: { name, value } }) => {
    let newValue = value;

    if (name === "amount") {
      if (value === "") {
        newValue = "";
      } else {
        newValue = Math.max(0, Number(value) || 0);
      }
    }

    setPayment((prevPayment) => ({
      ...prevPayment,
      [name]: newValue,
    }));
  };

  const handleInvoiceChange = (_ev, value) => {
    if (value && value.id) {
      const selectedInvoiceData = invoices.find(
        (invoice) => invoice.id === value.id
      );

      if (selectedInvoiceData) {
        setSelectedInvoice(value);
        setPayment((prevPayment) => ({
          ...prevPayment,
          payable_id: value.id,
          payable_type: "Invoice",
          amount:
            Number(selectedInvoiceData.total) -
            Number(selectedInvoiceData.paid_amount),
        }));
      }
    } else {
      setSelectedInvoice(null);
      setPayment((prevPayment) => ({
        ...prevPayment,
        payable_id: "",
        payable_type: "",
        amount: "",
      }));
    }
  };

  const handlePaymentFormChange = (_ev, value) => {
    if (value) {
      setSelectedPaymentForm(value);
      setPayment((prevPayment) => ({
        ...prevPayment,
        payment_form: value.Value,
      }));
    } else {
      setSelectedPaymentForm(null);
      setPayment((prevPayment) => ({
        ...prevPayment,
        payment_form: "",
      }));
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    try {
      const { data: newPayment } = await paymentsProvider.createPayment({
        payment,
      });
      navigate("/payments", {
        state: {
          feedback: "Pago creado correctamente",
          newPayment: JSON.stringify(newPayment),
        },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  useEffect(() => {});
  return (
    <MainLayout title="Nuevo Pago de Depósito">
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {formErrors && (
                  <MKAlert color="error" dismissible={true}>
                    {formErrors}
                  </MKAlert>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedInvoice}
                        options={
                          invoices.map((invoice) => ({
                            label: `#${invoice.id} - ${
                              invoice.unit.floor
                                ? `Piso ${invoice.unit.floor} `
                                : ""
                            }${invoice.unit.block ?? ""} ${invoice.unit.name}`,
                            id: invoice.id,
                          })) || []
                        }
                        getOptionLabel={(option) =>
                          option ? option.label : "Cobro"
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Cobro"
                            fullWidth
                          />
                        )}
                        onChange={handleInvoiceChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Fecha"
                        name="date"
                        type="date"
                        value={payment.date}
                        onChange={handleInputChange}
                        required={true}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Precio"
                        name="amount"
                        type="number"
                        value={payment.amount}
                        onChange={handleInputChange}
                        onFocus={handleFocusAmount}
                        onBlur={handleBlurAmount}
                        required={true}
                        fullWidth
                        InputProps={{
                          startAdornment:
                            focusedAmount || payment.amount ? (
                              <InputAdornment
                                position="start"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                $
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedPaymentForm}
                        options={paymentForms}
                        getOptionLabel={(option) =>
                          option
                            ? `[${option.Value}] ${option.Name}`
                            : "Forma de pago"
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Forma de pago"
                            fullWidth
                          />
                        )}
                        onChange={handlePaymentFormChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Número de referencia"
                        name="reference_number"
                        value={payment.reference_number}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Concepto"
                        name="concept"
                        value={payment.concept}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Notas"
                        name="notes"
                        value={payment.notes}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Crear pago de depósito
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}
export default NewPaymentDeposit;
