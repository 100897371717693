// React imports
import { useContext, useEffect, useState, useMemo } from "react";

// External Libraries
import { useLocation, useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material UI Components
import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

// Custom Components
import VideoLoader from "components/Animation/VideoLoader";

// Providers
import customersProvider from "providers/customersProvider";
import rentalsProvider from "providers/rentalsProvider";
import unitsProvider from "providers/unitsProvider";

// Contexts
import SessionContext from "contexts/SessionContext";

// Utilities
import { currencyFormatter } from "utils";

function NewRentalPage() {
  // Context and hooks
  const { currentUser } = useContext(SessionContext);
  const locationState = useLocation().state;
  const {
    unitId,
    newCustomerId,
    rentalData,
    customerData,
    isRenewing,
    isUnitChanged,
  } = locationState || {};
  const navigate = useNavigate();

  // Derived states or memoized values
  const nextYear = useMemo(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date.toISOString().split("T")[0];
  }, []);

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState("");

  // Data-specific states
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [units, setUnits] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [rental, setRental] = useState({
    unit_id: "",
    user_id: "",
    deposit: "",
    monthly_rent: "",
    start_date: new Date().toISOString().split("T")[0],
    end_date: nextYear,
    status: "al corriente",
    subsidiary_id: currentUser.subsidiary.id,
    user_guarantee_name: "",
    stored_goods: "",
    discount: "",
  });

  // Focus states
  const [focusedDiscount, setFocusedDiscount] = useState(false);
  const [focusedDeposit, setFocusedDeposit] = useState(false);

  // Handlers
  const handleFocusDiscount = () => setFocusedDiscount(true);
  const handleBlurDiscount = () => setFocusedDiscount(false);
  const handleFocusDeposit = () => setFocusedDeposit(true);
  const handleBlurDeposit = () => setFocusedDeposit(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [unitsData, customersData] = await Promise.all([
          unitsProvider.getUnits(null, { status_not_eq: "rentada" }),
          customersProvider.getCustomers(null, { status_not_eq: "bloqueado" }),
        ]);

        const unitsList = unitsData?.data?.data || [];
        setUnits(unitsList);
        const customersList = customersData?.data?.data || [];
        setCustomers(customersList);

        if (rental.unit_id) {
          const matchedUnit = unitsList.find(
            (unit) => unit.id === rental.unit_id
          );
          if (matchedUnit) {
            setSelectedUnit({
              label: `${matchedUnit.floor ?? ""}${matchedUnit.block ?? ""} ${
                matchedUnit.name
              }`,
              id: matchedUnit.id,
              default_price: matchedUnit.default_price,
            });
          }
        }

        if (unitId) {
          const unit = unitsList.find((unit) => unit.id === unitId);
          if (unit) {
            setSelectedUnit({
              label: `${unit.floor ?? ""}${unit.block ?? ""} ${unit.name}`,
              id: unit.id,
              default_price: unit.default_price,
            });
            setRental((prevRental) => ({
              ...prevRental,
              unit_id: unit.id,
              monthly_rent:
                unit.default_price * (1 - Number(rental.discount / 100)),
            }));
          }
        }

        if (rentalData) {
          setRental(rentalData);
        }

        if (newCustomerId) {
          const newCustomer = customersList.find(
            (customer) => customer.id === newCustomerId
          );
          if (newCustomer) {
            setSelectedCustomer({
              label: `${newCustomer.first_name} ${newCustomer.last_name}`,
              id: newCustomer.id,
            });
            setRental((prevRental) => ({
              ...prevRental,
              user_id: newCustomer.id,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // ignore the warning for rental.discount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId, newCustomerId, rental.unit_id]);

  useEffect(() => {
    const fetchUnitDetails = async () => {
      if (rentalData?.unit_id) {
        try {
          const unitResponse = await unitsProvider.getUnit(rentalData.unit_id);
          const unitData = unitResponse.data;

          setSelectedUnit({
            label: `${unitData.floor ?? ""}${unitData.block ?? ""} ${
              unitData.name
            }`,
            id: unitData.id,
            default_price: unitData.default_price,
          });
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (rentalData) {
      const endDate = new Date(rentalData.end_date);
      endDate.setFullYear(endDate.getFullYear() + 1);

      setRental((prevRental) => ({
        ...prevRental,
        unit_id: rentalData.unit_id,
        user_id: rentalData.user_id,
        deposit: "",
        monthly_rent: rentalData.monthly_rent || "",
        start_date: rentalData.end_date,
        end_date: endDate.toISOString().split("T")[0],
        status: "al corriente",
        subsidiary_id: currentUser.subsidiary.id,
        user_guarantee_name: rentalData.user_guarantee_name || "",
        stored_goods: rentalData.stored_goods || "",
        discount: "",
      }));

      fetchUnitDetails();

      const matchingCustomer = customers.find(
        (customer) => customer.id === rentalData.user_id
      );
      if (matchingCustomer) {
        setSelectedCustomer({
          label: `${matchingCustomer.first_name} ${matchingCustomer.last_name}`,
          id: matchingCustomer.id,
        });
      }
    }
  }, [rentalData, units, customers, currentUser.subsidiary.id]);

  useEffect(() => {
    if (customerData) {
      try {
        setRental((prevRental) => ({
          ...prevRental,
          user_id: customerData.user_id,
          user_guarantee_name: customerData.user_guarantee_name || "",
          stored_goods: customerData.stored_goods || "",
          start_date: new Date().toISOString().split("T")[0],
          end_date: nextYear,
        }));

        setSelectedCustomer({
          label: `${customerData.first_name} ${customerData.last_name}`,
          id: customerData.user_id,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [customerData, nextYear]);

  // Handles changes in the form input fields and updates rental state
  const handleInputChange = ({ target: { name, value } }) => {
    const formatValue = (value, min, max) => {
      let newValue = value === "0" ? "0" : value.replace(/^0+/, "") || "";
      if (newValue !== "") {
        newValue = Math.max(min, Math.min(max, Number(newValue))).toString();
      }
      return newValue;
    };

    let newValue;

    if (name === "discount") {
      newValue = formatValue(value, 0, 100);

      setRental((prevRental) => ({
        ...prevRental,
        discount: newValue,
      }));

      if (selectedUnit && selectedUnit.default_price && newValue !== "") {
        const discountAmount = (selectedUnit.default_price * newValue) / 100;
        const newDiscountedPrice = selectedUnit.default_price - discountAmount;
        setRental((prevRental) => ({
          ...prevRental,
          monthly_rent: newDiscountedPrice.toFixed(2),
        }));
      } else {
        setRental((prevRental) => ({
          ...prevRental,
          monthly_rent: "",
        }));
      }
    } else if (name === "deposit") {
      newValue = formatValue(value, 0, Infinity);
      setRental((prevRental) => ({
        ...prevRental,
        deposit: newValue,
      }));
    } else {
      newValue = value;
      setRental((prevRental) => ({
        ...prevRental,
        [name]: newValue,
      }));
    }
  };

  // Handles changes in the unit selection field and updates rental state
  const handleUnitChange = (_ev, value) => {
    if (value && value.id) {
      setSelectedUnit(value);
      setRental((prevRental) => ({
        ...prevRental,
        unit_id: value.id,
        monthly_rent: value.default_price * (1 - Number(rental.discount / 100)),
      }));
    } else {
      setSelectedUnit(null);
      setRental((prevRental) => ({
        ...prevRental,
        unit_id: "",
        monthly_rent: "",
      }));
    }
  };

  // Handles changes in the customer selection field and updates rental state
  const handleCustomerChange = (_ev, value) => {
    if (value && value.id) {
      setSelectedCustomer(value);
      setRental((prevRental) => ({
        ...prevRental,
        user_id: value.id,
      }));
    } else {
      setSelectedCustomer(null);
      setRental((prevRental) => ({
        ...prevRental,
        user_id: "",
      }));
    }
  };

  // Submits the form after validating the data and handling errors
  const submitForm = async (event) => {
    event.preventDefault();

    if (!selectedUnit || !selectedCustomer) {
      setFormErrors("Favor de seleccionar una unidad y un cliente");
      return;
    }

    if (isUnitChanged) {
      await rentalsProvider.updateRental(customerData.previous_rental, {
        status: "cancelado",
        motive: "Cambio de unidad",
      });
    }

    if (isRenewing) {
      await rentalsProvider.updateRental(rentalData.id, {
        status: "terminado",
      });
    }

    try {
      await rentalsProvider.createRental(rental);
      navigate("/rentals", {
        state: { feedback: "Contrato creado correctamente" },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  return (
    <MainLayout title="Nuevo Contrato">
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {formErrors && (
                  <MKAlert color="error" dismissible={true}>
                    {formErrors}
                  </MKAlert>
                )}
                {selectedUnit && (
                  <MKBox mb={3}>
                    <MKAlert color="info">
                      <MKBox color="white" fontSize="1rem">
                        Precio de la unidad seleccionada:
                        {currencyFormatter.format(selectedUnit.default_price)}
                        &nbsp;
                      </MKBox>
                      {rental.discount > 0 && (
                        <MKBox color="white" fontSize="1rem">
                          Precio con descuento aplicado:{" "}
                          {currencyFormatter.format(rental.monthly_rent)}
                        </MKBox>
                      )}
                    </MKAlert>
                  </MKBox>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        disabled={isRenewing}
                        value={selectedUnit}
                        options={units.map((unit) => ({
                          label: `${unit.floor ?? ""}${unit.block ?? ""} ${
                            unit.name
                          }`,
                          id: unit.id,
                          default_price: unit.default_price,
                        }))}
                        getOptionLabel={(option) => option?.label || "Unidad"}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            required
                            label="Unidad"
                            fullWidth
                          />
                        )}
                        onChange={handleUnitChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        disabled={isRenewing || isUnitChanged}
                        value={selectedCustomer}
                        options={[
                          { label: "+ Agregar nuevo cliente", id: "new" },
                          ...customers.map((customer) => ({
                            label: `${customer.first_name} ${customer.last_name}`,
                            id: customer.id,
                          })),
                        ]}
                        getOptionLabel={(option) => option?.label || "Cliente"}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            required
                            label="Cliente"
                            fullWidth
                          />
                        )}
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            style={{
                              fontWeight:
                                option.id === "new" ? "bold" : "normal",
                              color:
                                option.id === "new" ? "#FF4713" : "inherit",
                            }}
                          >
                            {option.label}
                          </li>
                        )}
                        onChange={(event, value) => {
                          if (value?.id === "new") {
                            navigate("/customers/new", {
                              state: {
                                fromNewRentalPage: true,
                                rentalData: rental,
                              },
                            });
                          } else {
                            handleCustomerChange(event, value);
                          }
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="deposit"
                        label="Depósito"
                        value={rental.deposit}
                        onChange={handleInputChange}
                        onFocus={handleFocusDeposit}
                        onBlur={handleBlurDeposit}
                        required={false}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          startAdornment:
                            focusedDeposit || rental.deposit ? (
                              <InputAdornment
                                position="start"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                $
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="start_date"
                        label="Fecha de inicio"
                        value={rental.start_date}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="end_date"
                        label="Fecha de fin"
                        value={rental.end_date}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="user_guarantee_name"
                        label="Aval"
                        value={rental.user_guarantee_name}
                        onChange={handleInputChange}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="stored_goods"
                        label="Bienes almacenados"
                        value={rental.stored_goods}
                        onChange={handleInputChange}
                        required={false}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="discount"
                        label="Descuento"
                        value={rental.discount}
                        onChange={handleInputChange}
                        onFocus={handleFocusDiscount}
                        onBlur={handleBlurDiscount}
                        required={false}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          endAdornment:
                            focusedDiscount || rental.discount ? (
                              <InputAdornment
                                position="end"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                %
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Crear Contrato
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}

export default NewRentalPage;
